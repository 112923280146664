import commonConfig from './common';

import accountConfig from '../account/_config';
import animationConfig from '../animation/_config';
import apiConfig from '../api/_config';
import appConfig from '../app/_config';
import contextConfig from '../context/_config';
import menuConfig from '../menu/_config';
import pageConfig from '../page/_config';
import printConfig from '../print/_config';
import applicationConfig from '../application/_config';

// import langs from '../locale/langs';
// import templates from '../template/templates';

const config = (di) => {
	commonConfig(di);

	accountConfig(di);
	animationConfig(di);
	apiConfig(di);
	appConfig(di);
	contextConfig(di);
	menuConfig(di);
	pageConfig(di);
	printConfig(di);
	applicationConfig(di);


	const bodyData = di.get('dom/dataAttrFactory')({element: document.body});
	const baseUrl = bodyData.get('baseUrl');
	const apiUrl = bodyData.get('apiUrl');
	const registerUrl = bodyData.get('registerUrl');

	di
		.setValue('baseUrl', baseUrl)
		.setValue('baseThumbUrl', bodyData.get('baseThumbUrl'))
		.setValue('relPath', bodyData.get('relPath'))
		.setValue('applicationUrls', bodyData.get('applicationUrls'))
		.setValue('api/baseUrl', apiUrl)
		.setValue('account/registerUrl', registerUrl)
		.setValue('account/defaultUrl', bodyData.get('accountDefaultUrl'))
		.setValue('account/loggedDefaultUrl', bodyData.get('accountLoggedDefaultUrl'))

		// we keep the translated strings in separated files
		// eslint-disable-next-line piggyback/no-restricted-global-extend
		.setValue('locale/langs', window.appLangs)
		.setValue('locale/defaultLanguage', 'de')

		// we keep the templates in separated files
		// eslint-disable-next-line piggyback/no-restricted-global-extend
		.setValue('template/tpls', window.appTemplates)

		.setParam('PageLoader', 'baseUrl', baseUrl)
		.setParam('Navigation', 'navigateSameUrl', true)

		.setParams('AccountContext', {popOnKey: false, closeOnClickOutside: false})

		//.setSetter('PageLoader', 'injectRouter', di.lazyGet('router/router'))

		.setParam('FrontendApp', 'requiredIconFiles', bodyData.get('iconFiles'))

		.addPostCreateCall('Template', (template) => {
			template.registerHelpers({
				text: di.newInstance('LocaleHelper'),
				html: di.newInstance('HtmlHelper'),
				date: di.newInstance('DateHelper'),
				id: di.newInstance('IdHelper'),
				string: di.newInstance('StringHelper'),
				url: di.newInstance('UrlHelper'),
				thumb: di.newInstance('ThumbHelper')
			});
		})

		.addPostCreateCall('PageComponentFactory', (factory) => {
			factory.setAliases({
				AccountManager: 'FrontendAccountManager'
			});
		})

		.setValue('menu/mediaQuery', '(max-width: 768px)')

		;
};


export default config;
