import {isString, isFunction} from '../utils/types';
import domMixin from '../dom/dom-mixin';


class LocaleText extends domMixin() {

    constructor({langs, defaultLanguage, root = null, changeEventName = 'localetext:langchange'}) {
		super();
		this.root = root ? root : document.body;
		this.langs = langs;
		this.defaultLanguage = defaultLanguage;
		this.changeEventName = changeEventName;
	}


	getDefaultLanguage() {
		return this.defaultLanguage;
	}


	setDefaultLanguage(lang) {
		if (lang !== this.defaultLanguage) {
			const prev = this.defaultLanguage;
			this.defaultLanguage = lang;
			this.events.trigger(this.root, this.changeEventName, {previous: prev, current: lang});
		}
	}


	getChangeEventName() {
		return this.changeEventName;
	}


    get(id, params = {}) {
        return this.translate(this.defaultLanguage, id, params);
	}


    translate(lang, id, params = {}) {
        const text = this.getElement(lang, id);
        return this.format(text, params);
	}


	// check if the value is in the form of a textId and translate it, just in case
	// formats:
	// ':id:'
	// ':lang:id:'
	// [':id:', {params}]
	print(value) {
		let lang = this.defaultLanguage;
		let id = null;
		let params = {};
		if (Array.isArray(value)) {
			if (value.length === 2) {
				id = value[0];
				params = value[1];
			} else {
				throw new Error('Invalid TextId format');
			}
		} else if (value.length > 2 && value.charAt(0) === ':' && value.charAt(value.length  - 1) === ':') {
			id = value;
		}
		if (id !== null) {
			id = id.substr(1, id.length - 2);
			const sep = id.indexOf(':');
			if (sep >= 1) {
				[lang, id] = id.split(':', 2);
			}
			value = this.translate(lang, id, params);
		}
		return value;
	}


    format(text, params) {
        let value = '';
        if (!Array.isArray(text)) {
            text = [text];
        }

        let textPart;
        let method;
        for (let i = 0, end = text.length; i < end; i++) {
            textPart = text[i];
            method = (Array.isArray(textPart)? 'formatCondition' : 'formatString');
            value += this[method](textPart, params);
        }

        return value;
    }


    formatString(text, params) {
		if (isString(text)) {
			const value = text.replace(/\{([^{}]+)\}/gi, (all, key) => {
				if (isFunction(params)) {
					return params(all, key);
				}
				if (key in params) {
					return params[key];
				}
				return all;
			});
			return value.replace(/\\([{}])/g, '$1');
		}
		return text;
    }


    formatCondition(condition, params) {
        const conditionCount = condition.length;
        if (conditionCount < 4 || conditionCount > 5) {
            return '[Invalid condition]';
        }
        if(conditionCount === 4) {
            conditionCount.push('');
        }

        let conditionResult;
        const field = this.format(condition[0], params);
        const operator = condition[1];
        const value = this.format(condition[2], params);
        const thenVal = this.format(condition[3], params);
        const elseVal = (condition[4].length? this.format(condition[4], params) : '');

        switch (operator) {
			case '==':
				// eslint-disable-next-line eqeqeq
                conditionResult = (field == value);
                break;
            case '===':
                conditionResult = (field === value);
                break;
            case '!=':
				// eslint-disable-next-line eqeqeq
                conditionResult = (field != value);
                break;
            case '!==':
                conditionResult = (field !== value);
                break;
            case '>':
                conditionResult = (field > value);
                break;
            case '>=':
                conditionResult = (field >= value);
                break;
            case '<':
                conditionResult = (field < value);
                break;
            case '<=':
                conditionResult = (field <= value);
                break;
            default:
                conditionResult = false;
        }

        return (conditionResult? thenVal : elseVal);
    }


    getElement(lang, id) {
        let text;
        if (!Array.isArray(id)) {
            id = [id];
            text = id;
        } else {
            text = id[0];
        }

        let elementPath;
        let parts;
        let elementId;
        let elements;
        for (let i = 0, end = id.length; i < end; i++) {
            id[i] = lang + '/' + id[i];
            parts = id[i].split('/');
            elementId = parts.pop();
            elementPath = parts.join('/');
            elements = this.getElements(elementPath);
            if (elementId in elements) {
                text = elements[elementId];
                break;
            }
        }
        return text;
    }


    getElements(path) {
        return (path in this.langs? this.langs[path]() : {});
    }


}

export default LocaleText;
