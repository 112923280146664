import LocaleText from './locale-text';
import LocaleDynamicTranslator from './locale-dynamic-translator';
import localeMixin from './locale-mixin';


export default (di) => {
	di
		.setType({type: LocaleText, name: 'LocaleText', mixins: ['domMixin'], params: {
				langs: di.lazyValue('locale/langs', {}),
				defaultLanguage: di.lazyValue('locale/defaultLanguage', 'en')
			}
		})
		.set('locale/localeText', di.lazyNew('LocaleText'))

		.setType({type: LocaleDynamicTranslator, name: 'LocaleDynamicTranslator', mixins: ['domMixin', 'localeMixin'], initCall: 'init'})
		.set('locale/dynamicTranslator', di.lazyNew('LocaleDynamicTranslator'))

		.setMixin({mixin: localeMixin, name: 'localeMixin', setters: {
				injectLocaleText: di.lazyGet('locale/localeText'),
			}
		})

	;
};
