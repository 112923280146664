import TemplateHelper from './template-helper';


class LocaleHelper extends TemplateHelper {

	constructor({localeText}) {
		super();
		this.localeText = localeText;
    }

	get(id, params = {}) {
        return this.localeText.get(id, params);
    }


    translate(lang, id, params = {}) {
        return this.localeText.translate(lang, id, params);
	}


	format(text, params = {}) {
		return this.localeText.format(text, params);
	}


	print(value) {
		return this.localeText.print(value);
	}

}

export default LocaleHelper;
