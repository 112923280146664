import Container from './common/di/container';
import config from './app/config/config';

export default () => {
	const container = new Container();
	container.setValue('dom/dataAttrPrefix', 'vgb-');
	container.setValue('dom/classPrefix', 'vgb-js-');
	container.setValue('dom/baseClassPrefix', 'vgb-');
	config(container);

	const app = container.get('app');

	app.init().then(() => app.execute());
};

