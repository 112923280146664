const accountMixin = (Base = class Empty {}) => class extends Base {

	injectAccount(account) {
		this.account = account;
	}


	getAccountManager(accountManager) {
		return this.components.getComponent(this.components.getRoot(), 'accountManager');
	}

};

export default accountMixin;
